

export function isMobile (num) { // 验证手机
  return /^1[3|4|5|6|7|8|9]\d{9}$/.test(num)
}


export function isEmail (email) { // 验证邮箱
  return /^([a-zA-Z0-9]+[_|_|-|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(email)
}

export function isNumber (num) { // 验证数字
  return /^[0-9]*$/.test(num)
}

export function isFloat (num) { // 验证数字含1-3位小数
  return /^[0-9]+(.[0-9]{1,3})?$/.test(num)
}

export function isImage (num) { // 验证图片格式
  return /\.(gif|jpg|jpeg|bmp|png|svg|xbm|tif|pjp|jfif|avif|ico|tiff|svgz|webp)$/.test(num)
}

export function isVideo (num) { // 验证视频格式
  return /\.(ogm|wmv|mpg|webm|ogv|mov|axs|mpeg|mp4|m4v|avi|rmvb)$/.test(num)
}

export function isPassword (password) { // 验证密码
  return /^(?=.*\d)(?=.*[A-Za-z]).{6,16}$/.test(password)
}

export function isOnlyChinese (val) { // 只包含中文
  return /^[\u4e00-\u9fa5]+$/g.test(val)
}

/**
 * 获取两个数组的交集
 * @param {Array} source
 * @param {Array} target
 */
export function getIntersection (source, target) {
  return source.filter(it => new Set(target).has(it))
}


/**
 * @description 格式化金额,四舍五入
 * 示例： var num=number_format(1234567.089, 2, ".", ",");//1,234,567.09
 * @param number：要格式化的数字
 * @param decimals：保留几位小数 默认0位
 * @param decPoint：小数点符号 默认.
 * @param thousandsSep：千分位符号 默认为,
 * @param isCarryBit：是否进位 默认为true，进位
 */
export const formatMoney = (number, decimals = 0, decPoint = '.', thousandsSep = ',',isCarryBit=true) => {
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  let n = !isFinite(+number) ? 0 : +number
  let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  let sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
  let dec = (typeof decPoint === 'undefined') ? '.' : decPoint
  let s = ''
  let toFixedFix = function (n, prec) {
    let k = Math.pow(10, prec)
    if(isCarryBit){
      return '' + Math.ceil(n * k) / k
    }else {
      return '' + Math.floor(n * k) / k
    }
  }
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  let re = /(-?\d+)(\d{3})/
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2')
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}

export function htmlEncode (str) { // html编码
  let s = ''
  if (str.length === 0) return ''
  s = str.replace(/</g, '&lt;')
  s = s.replace(/>/g, '&gt;')
  s = s.replace(/ /g, '&nbsp;')
  s = s.replace(/'/g, '&#39;')
  s = s.replace(/'/g, '&quot;')
  s = s.replace(/(&lt;)br(&gt;)/gi, () => {
    if (arguments.length > 1) {
      return '<br>'
    }
  })
  return s
}

export function htmlDecode (str) { // html解码
  let s = ''
  if (str.length === 0) return ''
  s = str.replace(/&amp;/g, '&')
  s = s.replace(/&lt;/g, '<')
  s = s.replace(/&gt;/g, '>')
  s = s.replace(/&nbsp;/g, ' ')
  s = s.replace(/&#39;/g, '\'')
  s = s.replace(/&quot;/g, '"')
  return s
}

export function dateFormat (value, formatStr, pretty, serveTime) {
  const self = new Date(value)
  let str = formatStr
  const Week = ['日', '一', '二', '三', '四', '五', '六']

  if (self instanceof Date && isNaN(self.getTime())) {
    return ''
  }

  str = str.replace(/yyyy|YYYY/, self.getFullYear())
  str = str.replace(/yy|YY/, (self.getYear() % 100) > 9 ? (self.getYear() % 100).toString() : '0' + (self.getYear() % 100))

  str = str.replace(/MM/, (self.getMonth() + 1) > 9 ? (self.getMonth() + 1).toString() : '0' + (self.getMonth() + 1))
  str = str.replace(/M/g, (self.getMonth() + 1))

  str = str.replace(/w|W/g, Week[self.getDay()])

  str = str.replace(/dd|DD/, self.getDate() > 9 ? self.getDate().toString() : '0' + self.getDate())
  str = str.replace(/d|D/g, self.getDate())

  str = str.replace(/hh|HH/, self.getHours() > 9 ? self.getHours().toString() : '0' + self.getHours())
  str = str.replace(/h|H/g, self.getHours())
  str = str.replace(/mm/, self.getMinutes() > 9 ? self.getMinutes().toString() : '0' + self.getMinutes())
  str = str.replace(/m/g, self.getMinutes())

  str = str.replace(/ss|SS/, self.getSeconds() > 9 ? self.getSeconds().toString() : '0' + self.getSeconds())
  str = str.replace(/s|S/g, self.getSeconds())

  if (pretty === 'pretty') { // 人性化时间
    const now = serveTime || new Date()
    const differ = (now - self) / 1000
    const indexOfH = formatStr.indexOf('h') === -1 ? formatStr.indexOf('H') : formatStr.indexOf('h')
    if (now.getDate() === self.getDate() && now.getMonth() === self.getMonth()) {
      str = '今天'
      if (indexOfH > -1) { // 有小时则可以显示
        if (differ < 60 && differ > 0) {
          str = '刚刚'
        } else if (differ >= 60 && differ < 3600) {
          str = parseInt(differ / 60) + '分钟前'
        } else if (differ >= 3600 && parseInt(differ / 3600) <= now.getHours()) {
          str = parseInt(differ / 3600) + '小时前'
        } else if (-differ >= 60 && -differ < 3600) {
          str = -parseInt(differ / 60) + '分钟后'
        } else if (-differ >= 3600 && -parseInt(differ / 3600) <= (24 - now.getHours())) {
          str = -parseInt(differ / 3600) + '小时后'
        }
      }
    } else if (now.getDate() - self.getDate() === 1 && now.getMonth() === self.getMonth()) {
      str = '昨天'
      if (indexOfH > -1) {
        // str += self.format(formatStr.substr(indexOfH))
        str += dateFormat(self.getTime(), formatStr.substr(indexOfH))
      }
    } else if (now.getDate() - self.getDate() === 2 && now.getMonth() === self.getMonth()) {
      str = '前天'
      if (indexOfH > -1) {
        // str += self.format(formatStr.substr(indexOfH))
        str += dateFormat(self.getTime(), formatStr.substr(indexOfH))
      }
    } else if (now.getDate() - self.getDate() === -1 && now.getMonth() === self.getMonth()) {
      str = '明天'
      if (indexOfH > -1) {
        // str += self.format(formatStr.substr(indexOfH))
        str += dateFormat(self.getTime(), formatStr.substr(indexOfH))
      }
    } else if (now.getDate() - self.getDate() === -2 && now.getMonth() === self.getMonth()) {
      str = '后天'
      if (indexOfH > -1) {
        // str += self.format(formatStr.substr(indexOfH))
        str += dateFormat(self.getTime(), formatStr.substr(indexOfH))
      }
    } else if (self.getFullYear() === now.getFullYear()) {
      if (formatStr.indexOf('M') > -1) {
        formatStr = formatStr.substr(formatStr.indexOf('M'))
        // str = self.format(formatStr)
        str = dateFormat(self.getTime(), formatStr)
      }
    } else if (self.getFullYear() - now.getFullYear() === 1) {
      str = '明年'
      if (formatStr.indexOf('M') > -1) {
        formatStr = formatStr.substr(formatStr.indexOf('M'))
        if (now.getMonth() === 11) { // 12月不显示明年
          // str = self.format(formatStr)
          str = dateFormat(self.getTime(), formatStr)
        } else {
          // str += self.format(formatStr)
          str += dateFormat(self.getTime(), formatStr)
        }
      }
    } else if (self.getFullYear() - now.getFullYear() === -1) {
      str = '去年'
      if (formatStr.indexOf('M') > -1) {
        formatStr = formatStr.substr(formatStr.indexOf('M'))
        // str += self.format(formatStr)
        str += dateFormat(self.getTime(), formatStr)
      }
    }
  }
  return str
}